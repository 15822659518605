<template>
  <v-container>
     <confirm ref="confirm" />
     <v-row class="my-3 px-3">
      <h1>{{ trl("FC_TENANT_WRITE_menu") }}</h1>
      <v-spacer></v-spacer>
      <ForecastTools class="mt-2" />
     </v-row>
    <p class="mb-2" small>
      Du kannst mehrere Forecasts anlegen (z.B. ein FC je Geschäftsjahr).<br />
      Sind mehrere Forecasts gleichzeitig aktiv, wählen die Eintragenden aus, in
      welchen Forecast sie ihre Zahlen eingeben.
    </p>
    <p class="mb-4">
      Innerhalb eines Forecasts kannst du beliebig viele "Snapshots" definieren
      (z.B. einen Snapshot für jeden Monat des Geschäftsjahres), zu denen
      jeweils aktualisierte Zahlen gemeldet werden müssen.
    </p>
    <div>
      <v-card v-for="f in forecasts.slice().reverse()" :key="f.id" class="pa-3 mb-4">
        <v-row class="px-6">
          <h2 class="mt-3">
            <span>{{ f.title }}</span>
            <v-icon size="20" style="opacity:1;margin-left:1em;margin-right:0.5em">{{ f.vis ? 'mdi-monitor-eye' : 'mdi-eye-remove' }}</v-icon>
            <v-icon size="20" style="opacity:1;margin-right:0.5em">{{ f.active ? 'mdi-monitor-edit' : 'mdi-lock' }}</v-icon>
            </h2>
          <v-spacer></v-spacer>
          <ForecastForm :data="f">
              <v-btn icon class="mt-3"><v-icon>mdi-square-edit-outline</v-icon></v-btn>
          </ForecastForm>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="f.snapshots"
          :items-per-page="-1"
          class="elevation-0 mt-4"
          dense
          locale="de-DE"
          hide-default-footer
          calculate-widths
          :no-data-text="trl('no snapshots', f.title)"
          show-expand
        >
          <template v-slot:[`item.due_date`]="{ item }">
            <span
              :class="{ due: item.meta && item.meta.is_due && !item.closed_at }"
              >{{ d2s(item.due_date, "short") }}</span
            >
          </template>

          <template v-slot:[`item.commits`]="{ item }">
            <div
             
              style="text-align: center"
            >
              {{ getCommittedStatus(item.commits, item.status).text }}
            </div>
            
          </template>

          <template v-slot:[`item.closed_at`]="{ item }">
            <SnapshotConfirm
              v-if="item.meta && item.meta.allow_close"
              :action="'close'"
              :forecast="f"
              :snapshot="item"
            >
              <v-btn color="primary" dense text>Jetzt schließen</v-btn>
            </SnapshotConfirm>

            <div v-else-if="item.meta && item.meta.allow_commit">
              <v-icon small>mdi-timer-outline</v-icon
              ><span>warten auf Meldungen</span>
            </div>

            <div v-else>
             
              <v-icon small v-if="item.closed_at">mdi-lock</v-icon>
              <span>{{ d2s(item.closed_at, "short") }}</span>
              <!-- <v-icon
                small
                class="ml-2"
                color="primary"
                v-if="item.meta && item.meta.allow_reopen"
                @click="reopen(item)"
                >mdi-reply-circle</v-icon
              > -->
            </div>
          </template>

          <template v-slot:[`item.closed_by`]="{ item }">
            {{ getUsername(item.closed_by) }}
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-4">

              <table class="committed">
                <tr v-for="s in item.status" :key="s.division_id">
                  <td v-if="s.snapshot_id">
                    <v-chip
                      small
                      label
                      color="green"
                      text-color="white"
                      
                    >
                      <v-icon small>mdi-check-circle</v-icon>
                      <span>Zahlen gemeldet</span>
                    </v-chip>
                  </td>

                  <td v-else-if="s.created_at">
                    <v-chip
                      small
                      label
                      color="orange"
                      text-color="white"
                     
                    >
                      <v-icon small>mdi-record-circle-outline</v-icon>
                      <span>in Bearbeitung</span>
                    </v-chip>
                  </td>

                  <td v-else>
                    <v-chip
                      small
                      label
                      color="red darken-2"
                      text-color="white"
                     
                    >
                      <v-icon small>mdi-alert-circle</v-icon>
                      <span>keine Änderungen</span>
                    </v-chip>
                  </td>

                  <td>
                    <strong>
                      {{ divisions.find((d) => d.id === s.division_id).name }}
                    </strong>
                  </td>

                  <td v-if="s.snapshot_id">
                    gemeldet am {{ d2s(s.created_at, "short") }}
                  </td>
                  <td v-else-if="s.created_at">
                    letzte Änderung am {{ d2s(s.created_at, "short") }}
                  </td>
                  <td v-else>&nbsp;</td>

                  <td v-if="s.snapshot_id">von {{ s.created_by }}</td>
                  <td v-else-if="s.created_at">von {{ s.created_by }}</td>
                  <td v-else>&nbsp;</td>

                  <td>
                    
                     <Changelog
                      v-if="s.snapshot_id"
                      by="division_id"
                      :value="s.division_id"
                      :status="[2,1]"
                      :fcid="f.id"
                      :snapshot_id="s.snapshot_id"
                      field="total_wo_fk"
                      from="forecasts"
                    >
                    <v-icon small>mdi-timeline-text-outline</v-icon>
                    </Changelog> 

                    <Changelog
                      v-else-if="s.created_at"
                      by="division_id"
                      :value="s.division_id"
                      :status="[2,1]"
                      :fcid="f.id"
                      :moment="true"
                      field="total_wo_fk"
                      from="forecasts"
                      > <v-icon small>mdi-timeline-text-outline</v-icon>
                    </Changelog>    

                     
                      <v-icon v-else small style="visibility:hidden">mdi-timeline-text-outline</v-icon>
                    <v-btn
                      icon
                      plain
                      :to="{
                        name: 'BudgetDivision',
                        params: { tenant: current.path, id: s.division_id },
                        query: { forecast_id: f.id, snapshot_id: s.snapshot_id },
                      }"
                      ><v-icon small
                        >mdi-arrow-right-circle</v-icon
                      ></v-btn
                    >
                   
                  </td>
                </tr>
              </table>
            </td>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <SnapshotForm
              v-if="current.permissions.FC_TENANT_WRITE && f.active"
              :forecast="f"
              :snapshot="item"
            >
              <v-icon small class="mr-2">mdi-square-edit-outline</v-icon>
            </SnapshotForm>

            <Download
              v-if="
                current.permissions.FC_TENANT_EXPORT && item.commits.length > 0
              "
              :query="{
                tenant: current.id,
                fcid: item.forecast_id,
                snapshot: item.id,
              }"
            />

            <v-icon
                small
                class="ml-2"
                v-if="item.meta && item.meta.allow_reopen"
                @click="reopen(item)"
                title="Snapshot öffnen, um Meldungen zu erlauben"
                >mdi-lock-open-remove</v-icon
              >

          </template>
        </v-data-table>

        <v-card-actions class="mt-4">
          <!-- <v-btn text color="indigo" v-bind="attrs" v-on="on"> Snapshot hinzufügen </v-btn> -->
          <SnapshotForm
            v-if="current.permissions.FC_TENANT_WRITE && f.active"
            :forecast="f"
          >
            <v-btn color="primary" outlined >
              
              
          <v-icon class="mr-1">mdi-calendar-star</v-icon>
          <span>Snapshot hinzufügen</span>
            </v-btn>
          </SnapshotForm>
          <v-spacer></v-spacer>

        </v-card-actions>
      </v-card>
      <ForecastForm>
         <v-btn
        v-if="$store.getters.current.permissions.FC_TENANT_WRITE"
        color="primary"
        elevation="4"
        fab
        large
        class="fab-new"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      </ForecastForm>
    </div>
  </v-container>
</template>

<script>
import store from "@/store";
import { trl } from "@/utils/strings";
import { d2s } from "@/utils/dates";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import confirm from "@/components/modals/Confirm.vue";
import SnapshotForm from "@/components/forms/Snapshot";
import SnapshotConfirm from "@/components/modals/SnapshotConfirm";
import ForecastForm from "@/components/forms/Forecast";
import Download from "@/components/modals/Download";
import Changelog from "@/components/Changelog.vue";
import ForecastTools from "@/components/ForecastTools.vue";

export default {
  data() {
    return {
      headers: [
        {
          text: "Snapshot",
          value: "title",
        },

        {
          text: "Meldung bis",
          value: "due_date",
        },
        {
          text: "Abgabe-Status",
          value: "commits",
        },
        {
          text: "",
          value: "data-table-expand",
        },
        {
          text: "geschlossen am",
          value: "closed_at",
        },
        {
          text: "geschlossen von",
          value: "closed_by",
          align: "end",
        },
        { text: "Aktionen", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    trl, d2s,
    getCommittedStatus(commits, status_all) {
      return {
        text: commits.length + " / " + status_all.length,
        text_percent:
          Math.round((100 / status_all.length) * commits.length) + "%",
        percent: (100 / status_all.length) * commits.length,
        remaining: status_all.length - commits.length,
      };
    },

    isActiveSnapshot(item) {
      if (!this.current.permissions.FC_TENANT_CLOSE) return false;
      return (
        DateTime.fromSQL(item.start_date) <= DateTime.now() &&
        item.closed_at === null
      );
    },

    async reopen(item) {

      let confirmed = await this.$refs.confirm.open(
        "Meldungen wieder zulassen",
        `Willst du die Meldung der Zahlen für diesen Snapshot wieder erlauben?<br><br>
        Alle Zahlen, die dann aus den Units gemeldet werden, fließen bis zur erneuten Schließung in diesen Snapshot ein.`,
        { confirmLabel: "Meldungen zulassen", cancelLabel: trl('cancel'), color: "primary", width: 500 }
      );

      if (!confirmed) return;

      this.$store.dispatch("saveSnapshot", {
        id: item.id,
        closed_at: null,
        closed_by: null,
      });
    },
    getUsername(id) {
      if (!id) return;
      const usr = this.users.find((u) => u.id === id);
      return usr.firstname + " " + usr.lastname;
    }
  },
  computed: {
    ...mapGetters(["current", "forecasts", "divisions", "userDivisions", "users"]),
  },
  created() {
    this.$store.dispatch("req", {
      method: "GET",
      route: "forecasts?with_last",
      mutation: "forecasts",
    });
  },
  components: { SnapshotForm, SnapshotConfirm, ForecastForm, Download, confirm, Changelog, ForecastTools },
  beforeRouteEnter(to, from, next) {
    if (store.getters.current.permissions.FC_TENANT_WRITE) {
      store.state.ui.hideForecastSelector = true;
      next();
    } else {
      next({ name: "NotFound" });
    }
  },
  beforeRouteLeave(to, from, next) {
    store.state.ui.hideForecastSelector = false;
    next();
  }
};
</script>

<style lang="scss" >
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  background-color: #eee;
}

table.committed {
  border-collapse: collapse;
  width: 100%;
}

table.committed tr {
  border-bottom: 1px solid #cacaca;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #ccc;
  }
}

table.committed td {

  padding: 0px 24px 0px 12px;

  .v-chip {
    width: 140px;
  }
}

.due {
  color: red;
}
</style>