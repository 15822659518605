<template>
  <v-dialog v-model="dialog" persistent max-width="680px">
     <confirm ref="confirm" />
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"><slot></slot></span>
    </template>
    <v-card>
      <v-card-title class="text-h6 primary white--text mb-6">
        {{ ui.headline }}
      </v-card-title>
      <v-form>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Titel"
                placeholder="z.B. #01"
                required
                v-model="title"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-menu
                v-model="datePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="localeDate"
                    label="Meldung bis"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    persistent-hint
                    :hint="trl('commit_days_in_advance', $store.state.budget.commits.days_in_advance)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="due_date"
                  :locale="$store.state.me.locale"
                  :min="minDate"
                  first-day-of-week="1"
                   no-title
                  color="primary"
                  header-color="primary"
                  @input="datePicker = false"
                ></v-date-picker>
              </v-menu>
              <small></small>
            </v-col>
            
          </v-row>
        </v-container>
        
      </v-card-text>
      <v-card-actions>
         <v-btn depressed @click="del" v-if="snapshot">
          {{ trl("delete") }}...
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialog = false">
          {{ trl("cancel") }}
        </v-btn>
        <v-btn color="primary" @click="save">
          {{ trl(ui.submitLabel) }}
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { trl } from "@/utils/strings";
import { DateTime } from "luxon";
import confirm from "@/components/modals/Confirm";
export default {
  props: ["forecast", "snapshot"],
  data: () => ({
    dialog: false,
    datePicker: false,
    minDate: DateTime.now().toISODate(),
    title: "",
    due_date: null,
    ui: {
      submitLabel: "save",
      headline: "",
    },
  }),
  methods: {
    start_date_change() {
      this.due_date = DateTime.fromISO(this.start_date)
        .plus({ days: 7 })
        .toISODate();
    },
    save() {
      
      let snapshot = {
        forecast_id: this.forecast.id,
        title: this.title,
        due_date: this.due_date + " " + DateTime.fromSQL(this.$store.state.budget.commits.time_on_due_date).setZone(process.env.VUE_APP_SQL_TZ).toFormat('HH:mm:ss')
      };

      if (this.snapshot) {
        snapshot.id = this.snapshot.id;
      }

      let collisions = false;

      this.forecast.snapshots.forEach(snap => {

          if (this.snapshot && snap.id === this.snapshot.id) {
            return;
          }

          if (snap.due_date === snapshot.due_date) {
            collisions = true;
          }
      })

      if (collisions) {
        alert("Es kommt zu Überschneidungen mit bestehenden Snapshots!")
        return;
      }

      this.$store.dispatch("saveSnapshot", snapshot);
      this.dialog = false;
    },
    async del() {
      let confirmed = await this.$refs.confirm.open(
          "Snapshot löschen",
          `Willst du diesen Snapshot endgültig löschen?`,
          { confirmLabel: trl('delete'), cancelLabel: trl('cancel'), color: "primary", width: 500 }
        );

      if (!confirmed) return;

      this.dialog = false;
      this.$store.dispatch("deleteSnapshot", {
            id: this.snapshot.id
          });

    },
    trl,
  },
  computed: {
    ...mapGetters(["forecasts"]),
    localeDate(){
      let due_time  = DateTime.fromSQL(this.$store.state.budget.commits.time_on_due_date).setZone(this.$store.state.me.timezone).toFormat('HH:mm:ss').split(":");
      return this.due_date ? DateTime.fromISO(this.due_date).plus({hours: due_time[0], minutes: due_time[1]}).setLocale(this.$store.state.me.locale).toFormat("dd.LL.yy, HH:mm 'Uhr'") : "";
    }
  },
  components: { confirm },
  watch: {
    dialog: function (val) {
      if (!val) return;

      this.title = "";
      this.due_date = DateTime.now().toISODate();
      this.minDate = DateTime.now().toISODate();
      this.ui.headline = this.forecast.title + ": Snapshot hinzufügen";

      if (this.snapshot) {
        (this.ui.headline = this.forecast.title + ": Snapshot bearbeiten"),
          (this.ui.submitLabel = "save changes"),
          (this.title = this.snapshot.title);
       
          this.due_date = (this.snapshot.due_date || "").split(" ")[0];
      } else {
        let minDate = "";
        let snap = this.forecast.snapshots.filter(s => s.meta.allow_commit);
        if (snap.length > 0) {
          this.minDate = DateTime.fromSQL(snap[0].due_date).plus({days: 1}).toISODate();
          this.due_date = this.minDate;
        }
      }
    },
  },
};
</script>