<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
    </template>
    <v-card>
      <v-card-title class="text-h6 primary white--text mb-6">
        {{ forecast.title }}<v-icon class="white--text">mdi-chevron-right</v-icon> Snapshot "{{ snapshot.title }}" {{ trl(action) }}
      </v-card-title>
      <v-card-text v-html="committedStatusText"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">
          {{ trl("cancel") }}
        </v-btn>
        <v-btn color="primary"  @click="confirmAction">
          {{ trl(submit) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { trl, numerus } from "@/utils/strings";
import { mapGetters } from "vuex";

export default {
  props: ["action", "snapshot", "forecast"],
  methods: {
    confirmAction() {
      switch (this.action) {
        case "close":
          this.$store.dispatch("saveSnapshot", {
            id: this.snapshot.id,
            closed_at: true,
          });
          break;
          case "delete":
          this.$store.dispatch("deleteSnapshot", {
            id: this.snapshot.id
          });
          break;
        default:
      }

      this.dialog = false;
    },
    trl,
  },
  data() {
    return {
      dialog: false,
      title: "",
      text: "",
      submit: "",
    };
  },
  computed: {
    ...mapGetters(["userDivisions"]),
    committedStatusText(){
      let commits = this.snapshot.commits.length;
      let all = this.userDivisions.filter(d => !d.deleted_at).length;
      let icon = commits === all ? "mdi-checkbox-marked-circle" : "mdi-alert";
      let string = commits === all ? "Alle Units haben ihre Zahlen gemeldet." : numerus('Achtung! Es hat||haben erst ? Unit|s ihre Zahlen gemeldet!', commits);
      return `<i class="mdi ${icon}"></i><span>${string}</span>`;
    }
  },
  watch: {
    dialog: function (val) {
      if (!val) return;

      if (this.action === "close") {
        this.submit = "Jetzt schließen";
      } else if (this.action === "delete") {
        this.submit = "Löschen";
      }
    },
  },
};
</script>
