<template>
 
    <v-dialog
      v-model="dialog"
      width="640"
      persistent
    >
    <confirm ref="confirm" />
      <template v-slot:activator="{ on, attrs }">
         <span v-bind="attrs" v-on="on"><slot></slot></span>
      </template>

      <v-card>
        <v-card-title class="text-h6 primary white--text mb-6">
          {{ headline }}
        </v-card-title>
        <v-card-text>
    
          <v-text-field v-model.trim="forecast.title" outlined label="Forecast-Titel"></v-text-field>
          <v-select v-model="forecast.fy" :items="fiscalYears" outlined label="Geschäftsjahr"></v-select>
          <v-switch v-model="forecast.active" label="aktiv (Snapshots können hinzugefügt und Budget erfasst werden)"></v-switch>
          <v-switch v-model="forecast.vis" label="sichtbar (Forecast kann im System aufgerufen werden)"></v-switch>
        </v-card-text>

        <v-divider></v-divider>

        <v-banner color="warning" class="mb-3" v-if="!this.forecast.id">
          Wenn du nur einen neuen Abgabetermin zu einem bestehenden Forecast hinzufügen willst, klicke auf "Abbrechen" und wähle "Snapshot hinzufügen" im entsprechenden Forecast!
        </v-banner>

        <v-card-actions>
          <v-btn
            v-if="forecast.id"
            depressed
            color="grey lighten-2"
            @click="del"
            >{{ trl('delete') }}...</v-btn>
          <v-spacer></v-spacer>
           <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            {{ trl('cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
          >
            {{ trl('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>
<script>
import { trl } from "@/utils/strings";
import confirm from "@/components/modals/Confirm.vue";
import { mapGetters } from "vuex";

export default {
  props: ["data"],
    data(){
        return {
            dialog: false,
            headline: "",
            forecast: {}
        }
    },
    methods: {
      trl,
      async save(){
        this.dialog = false;
        this.forecast.vis = this.forecast.vis ? 1 : 0
        this.forecast.active = this.forecast.active ? 1 : 0

        await this.$store.dispatch("req", {
          route: "forecast", 
          data: this.forecast
        })

        this.$store.dispatch("req", {method: "GET", route: "forecasts", mutation: "forecasts"});

      },
      async del(){

        if (this.data.snapshots.length > 0) {
            this.$store.commit("alert", {
              title:"Löschen nicht möglich",
              text: "Der Forecast kann nicht gelöscht werden, da er bereits Daten enthält."
            })
            return;
          }


        let confirmed = await this.$refs.confirm.open(
          "Forecast löschen",
          `Willst du diesen Forecast endgültig löschen?`,
          { confirmLabel: "Löschen", cancelLabel: trl('cancel'), color: "primary", width: 500 }
        );

      if (!confirmed) return;

        await this.$store.dispatch("req", {
          method: "DELETE",
          route: "forecast", 
          data: this.forecast
        })

         this.$store.dispatch("req", {method: "GET", route: "forecasts", mutation: "forecasts"});

      }
    },
    computed: {
      ...mapGetters(["current"]),
      fiscalYears(){
        let arr = [2020];

        for (let i = arr[0]; i < (new Date().getFullYear()) + 3; i++) arr.push(i)
        return arr;
      }
    },
    components: { confirm },
     watch: {
    dialog: function (val) {
      if (!val) {
        return;
      }

     
      if (this.data) {

        this.headline = this.data.title + " bearbeiten";

        this.forecast = {
          id: this.data.id,
          tenant_id: this.data.tenant_id,
          title: this.data.title,
          fy: this.data.fy,
          vis: this.data.vis,
          active: this.data.active
        }
      } else {

        this.headline = "Neuen Forecast anlegen";

        this.forecast = {
          tenant_id: this.current.id,
          title: "Forecast " + (new Date().getFullYear() + 1),
          fy: (new Date().getFullYear() + 1),
          vis: false,
          active: true
        }
      }

    },
    // 'forecast.vis': function (val) {
    //     if(!val) {
    //       this.forecast.active = false;
    //     }
    // }
  }
}
</script>